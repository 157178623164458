<template>
  <div id="top">
    <div
      v-if="
        typeof error_messages === 'object' &&
          Object.keys(error_messages).length > 0
      "
      class="alert alert-danger"
    >
      <ul>
        <template v-for="(error, i) in error_messages">
          <span v-bind:key="i">
            <li v-for="(message, ii) in error" v-bind:key="ii">
              {{ message }}
            </li>
          </span>
        </template>
      </ul>
    </div>
    <div class="tabs">
      <v-tabs
        v-model="tab"
        background-color="light primary"
        class="elevation-2"
        dark
      >
        <v-tab>{{ $t("COMPONENTS.MATERIALREQUEST") }}</v-tab>
        <!-- <v-tab>{{ $t("BUTTON.FILES") }}</v-tab> -->
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item>
          <v-card flat>
            <v-card-text>
              <v-row>
                <v-col cols="6">
                  <div class="form-group">
                    <label for="">
                      <span> Клиент:</span>
                    </label>
                    <div class="field-wrap">
                      <vu-select
                        :options="vendors"
                        label="name"
                        v-model="materialRequest.clientId"
                        :value="materialRequest.clientId"
                        :reduce="vendors => vendors.id"
                      ></vu-select>
                    </div>
                    <div v-if="$v.materialRequest.$error">
                      <div
                        class="text-danger"
                        v-if="!$v.materialRequest.clientId.required"
                      >
                        {{ $t("VALIDATIONS.REQUIRED") }}
                      </div>
                    </div>
                  </div>
                </v-col>

                <v-col cols="6">
                  <div class="form-group">
                    <label for="">
                      <span> Дата:</span>
                    </label>
                    <div class="field-wrap">
                      <datetime
                        zone="local"
                        value-zone="local"
                        type="date"
                        v-model="materialRequest.date"
                        input-class="form-control"
                      ></datetime>
                    </div>
                    <div v-if="$v.materialRequest.$error">
                      <div
                        class="text-danger"
                        v-if="!$v.materialRequest.date.required"
                      >
                        {{ $t("VALIDATIONS.REQUIRED") }}
                      </div>
                    </div>
                  </div>
                </v-col>

                <!-- <v-col cols="6">
                  <div class="form-group">
                    <label for="">
                      <span> Приел:</span>
                    </label>
                    <div class="field-wrap">
                      <vu-select
                        :options="users"
                        label="name"
                        v-model="materialRequest.acceptedId"
                        :value="materialRequest.acceptedId"
                        :reduce="users => users.id"
                      ></vu-select>
                    </div>
                  </div>
                </v-col>
                <v-col cols="6">
                  <div class="form-group">
                    <label for="">
                      <span> Предал:</span>
                    </label>
                    <div class="field-wrap">
                      <vu-select
                        :options="users"
                        label="name"
                        v-model="materialRequest.betrayerId"
                        :value="materialRequest.betrayerId"
                        :reduce="users => users.id"
                      ></vu-select>
                    </div>
                  </div>
                </v-col> -->
              </v-row>
              <hr />
              <template v-if="materialRequest.details.length > 0">
                <v-expansion-panels accordion>
                  <v-expansion-panel
                    v-for="(detail, i) in materialRequest.details"
                    v-bind:key="i"
                  >
                    <v-expansion-panel-header>
                      <div class="text-left">
                        <h4>
                          {{ getProduct(detail.rawProductId) }}
                          | Брой първични единици:
                          {{ detail.numberPrimaryUnits }} | Партида:
                          {{ detail.batch }}
                        </h4>
                      </div>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-row>
                        <v-col cols="6">
                          <label for=""><span> Суровина:</span></label>
                          <vu-select
                            :options="raw_products"
                            label="name"
                            v-model="detail.rawProductId"
                            :value="detail.rawProductId"
                            :reduce="raw_products => raw_products.id"
                            @input="setQuantity('numberPrimaryUnits', detail)"
                          ></vu-select>
                        </v-col>
                        <v-col cols="6">
                          <label for=""
                            ><span> Брой първични единици:</span></label
                          >
                          <input
                            type="text"
                            class="form-control"
                            v-model="detail.numberPrimaryUnits"
                            @input="setQuantity('numberPrimaryUnits', detail)"
                          />
                        </v-col>
                        <v-col cols="6">
                          <label for=""><span> Брой опаковки:</span></label>
                          <input
                            type="text"
                            class="form-control"
                            v-model="detail.packages"
                            @input="setQuantity('packages', detail)"
                          />
                        </v-col>

                        <v-col cols="6">
                          <div class="form-group">
                            <label for="">
                              <span> Партида:</span>
                            </label>
                            <div class="field-wrap">
                              <input
                                type="text"
                                v-model="detail.batch"
                                class="form-control"
                              />
                            </div>
                          </div>
                        </v-col>
                        <v-col cols="6">
                          <label for=""><span> Държава:</span></label>
                          <input
                            type="text"
                            class="form-control"
                            v-model="detail.country"
                          />
                        </v-col>
                        <v-col cols="6">
                          <label for=""><span> Срок на годност:</span></label>
                          <datetime
                            zone="local"
                            value-zone="local"
                            type="date"
                            v-model="detail.expirationDate"
                            input-class="form-control"
                          ></datetime>
                        </v-col>
                        <!-- <v-col cols="6">
                          <label for=""
                            ><span>
                              Срок на годност / <strong>ПРОДАВА</strong>:</span
                            ></label
                          >
                          <datetime
                            zone="local"
                            value-zone="local"
                            type="date"
                            v-model="detail.sell"
                            input-class="form-control"
                          ></datetime>
                        </v-col>
                        <v-col cols="6">
                          <label for=""
                            ><span>
                              Срок на годност:</span
                            ></label
                          >
                          <datetime
                            zone="local"
                            value-zone="local"
                            type="date"
                            v-model="detail.expirationDate"
                            input-class="form-control"
                          ></datetime>
                        </v-col> -->
                        <v-col cols="6">
                          <div class="form-group">
                            <label for="">
                              <span> От склад:</span>
                            </label>
                            <div class="field-wrap">
                              <vu-select
                                :options="warehouses"
                                label="name"
                                v-model="detail.fromWarehouseId"
                                :value="detail.fromWarehouseId"
                                :reduce="warehouses => warehouses.id"
                              ></vu-select>
                            </div>
                          </div>
                        </v-col>
                        <v-col cols="6">
                          <div class="form-group">
                            <label for="">
                              <span> Към склад:</span>
                            </label>
                            <div class="field-wrap">
                              <vu-select
                                :options="warehouses"
                                label="name"
                                v-model="detail.toWarehouseId"
                                :value="detail.toWarehouseId"
                                :reduce="warehouses => warehouses.id"
                              ></vu-select>
                            </div>
                          </div>
                        </v-col>
                        <v-col cols="12">
                          <v-btn
                            @click="deleteDetail(i)"
                            class="btn btn-danger pull-right btn-elevate kt-login__btn-primary text-white"
                            >{{ $t("BUTTON.DELETE") }}</v-btn
                          >
                        </v-col>
                      </v-row>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </template>

              <v-row>
                <v-col cols="6">
                  <label for=""><span> Суровина:</span></label>
                  <vu-select
                    :options="raw_products"
                    label="name"
                    v-model="newDetail.rawProductId"
                    :value="newDetail.rawProductId"
                    :reduce="raw_products => raw_products.id"
                    @input="setQuantity('numberPrimaryUnits', newDetail)"
                  ></vu-select>
                  <div v-if="$v.newDetail.$error">
                    <div
                      class="text-danger"
                      v-if="!$v.newDetail.rawProductId.required"
                    >
                      {{ $t("VALIDATIONS.REQUIRED") }}
                    </div>
                  </div>
                </v-col>
                <v-col cols="6">
                  <label for=""><span> Брой първични единици:</span></label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="newDetail.numberPrimaryUnits"
                    @input="setQuantity('numberPrimaryUnits', newDetail)"
                  />
                  <div v-if="$v.newDetail.$error">
                    <div
                      class="text-danger"
                      v-if="!$v.newDetail.numberPrimaryUnits.required"
                    >
                      {{ $t("VALIDATIONS.REQUIRED") }}
                    </div>
                  </div>
                </v-col>
                <v-col cols="6">
                  <label for=""><span> Брой опаковки:</span></label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="newDetail.packages"
                    @input="setQuantity('packages', newDetail)"
                  />
                  <div v-if="$v.newDetail.$error">
                    <div
                      class="text-danger"
                      v-if="!$v.newDetail.packages.required"
                    >
                      {{ $t("VALIDATIONS.REQUIRED") }}
                    </div>
                  </div>
                </v-col>
                <v-col cols="6">
                  <div class="form-group">
                    <label for="">
                      <span> Партида:</span>
                    </label>
                    <div class="field-wrap">
                      <input
                        type="text"
                        v-model="newDetail.batch"
                        class="form-control"
                      />
                      <div v-if="$v.newDetail.$error">
                        <div
                          class="text-danger"
                          v-if="!$v.newDetail.batch.required"
                        >
                          {{ $t("VALIDATIONS.REQUIRED") }}
                        </div>
                      </div>
                    </div>
                  </div>
                </v-col>
                <v-col cols="6">
                  <div class="form-group">
                    <label for="">
                      <span> Държава:</span>
                    </label>
                    <div class="field-wrap">
                      <input
                        type="text"
                        v-model="newDetail.country"
                        class="form-control"
                      />
                      <div v-if="$v.newDetail.$error">
                        <div
                          class="text-danger"
                          v-if="!$v.newDetail.country.required"
                        >
                          {{ $t("VALIDATIONS.REQUIRED") }}
                        </div>
                      </div>
                    </div>
                  </div>
                </v-col>
                <v-col cols="6">
                  <label for=""><span> Срок на годност:</span></label>
                  <datetime
                    zone="local"
                    value-zone="local"
                    type="date"
                    v-model="newDetail.expirationDate"
                    input-class="form-control"
                  ></datetime>
                  <div v-if="$v.newDetail.$error">
                    <div
                      class="text-danger"
                      v-if="!$v.newDetail.expirationDate.required"
                    >
                      {{ $t("VALIDATIONS.REQUIRED") }}
                    </div>
                  </div>
                </v-col>

                <v-col cols="6">
                  <div class="form-group">
                    <label for="">
                      <span> От склад:</span>
                    </label>
                    <div class="field-wrap">
                      <vu-select
                        :options="warehouses"
                        label="name"
                        v-model="newDetail.fromWarehouseId"
                        :value="newDetail.fromWarehouseId"
                        :reduce="warehouses => warehouses.id"
                      ></vu-select>
                    </div>
                    <div v-if="$v.newDetail.$error">
                      <div
                        class="text-danger"
                        v-if="!$v.newDetail.fromWarehouseId.required"
                      >
                        {{ $t("VALIDATIONS.REQUIRED") }}
                      </div>
                    </div>
                  </div>
                </v-col>
                <v-col cols="6">
                  <div class="form-group">
                    <label for="">
                      <span> Към склад:</span>
                    </label>
                    <div class="field-wrap">
                      <vu-select
                        :options="warehouses"
                        label="name"
                        v-model="newDetail.toWarehouseId"
                        :value="newDetail.toWarehouseId"
                        :reduce="warehouses => warehouses.id"
                      ></vu-select>
                    </div>
                    <div v-if="$v.newDetail.$error">
                      <div
                        class="text-danger"
                        v-if="!$v.newDetail.toWarehouseId.required"
                      >
                        {{ $t("VALIDATIONS.REQUIRED") }}
                      </div>
                    </div>
                  </div>
                </v-col>
                <v-col cols="12">
                  <v-btn
                    @click="saveDetail()"
                    class="btn btn-success btn-elevate kt-login__btn-primary text-white"
                    >Добави запис
                  </v-btn>
                </v-col>
              </v-row>

              <hr />
              <v-btn
                @click="save()"
                class="btn btn-success btn-elevate kt-login__btn-primary text-white"
                >{{ $t("BUTTON.SAVE") }}
              </v-btn>
            </v-card-text>
          </v-card>
        </v-tab-item>

        <v-tab-item>
          <v-container class="pt-0">
            <v-row class="mb-6 t-head mt-0">
              <v-col> Име </v-col>
              <v-col> Съдаден на </v-col>
              <v-col> Променен на </v-col>
              <v-col> Операции </v-col>
            </v-row>

            <v-row class="mb-6 t-row" v-for="file of files" :key="file.id">
              <v-col class="t-col">
                {{ file.filename }}
              </v-col>
              <v-col class="t-col">
                {{ file.createdAt }}
              </v-col>
              <v-col class="t-col">
                {{ file.updatedAt }}
              </v-col>
              <v-col class="t-col">
                <a href="#" v-on:click="getFile(file.id)" class="mr-4"
                  >Изтегли документ</a
                >
                <!-- <a href="#" class="float-right" @click="deleteData(item)">Изтрий</a> -->
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <vue-dropzone
                  ref="myVueDropzone"
                  id="dropzone"
                  :options="dropzoneOptions"
                ></vue-dropzone>
              </v-col>
            </v-row>
          </v-container>
        </v-tab-item>
      </v-tabs-items>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
import moment from "moment";

import {
  GET_MATERIALREQUEST,
  SAVE_MATERIALREQUEST,
  UPDATE_MATERIALREQUEST
} from "@/store/materialRequest.module";

import { GET_ALL_CLIENTS } from "@/store/clients.module";

import { GET_ALL_RAW_PRODUCTS } from "@/store/rawProducts.module";

import { GET_ALL_WAREHOUSES } from "@/store/warehouses.module";

import { GET_ALL_USERS } from "@/store/users.module";

import { mapGetters, mapState } from "vuex";
// Vue.use(VueFormGenerator)
import _ from "lodash";
import { required } from "vuelidate/lib/validators";

import { GET_FILES_BY_TYPE, GET_FILE } from "@/store/files.module";

import { APP_URL } from "@/common/config";

import vue2Dropzone from "vue2-dropzone";

export default {
  name: "createUpdate",

  components: {
    vueDropzone: vue2Dropzone
  },
  computed: {
    ...mapGetters(["isLoadingMaterialRequest"]),
    ...mapState({
      errors: state => state.auth.errors
    }),
    isEdit: function() {
      if (typeof this.$route.params.id !== "undefined") {
        return true;
      } else {
        return false;
      }
    }
  },
  data() {
    return {
      error_messages: [],
      model: "materialRequests",
      tab: 0,
      isSubmitDisabled: true,
      materialRequest: {
        details: []
      },
      vendors: [],
      users: [],
      warehouses: [],
      raw_products: [],
      newDetail: {},
      files: [],
      dropzoneOptions: {
        url: "https://httpbin.org/post",
        thumbnailWidth: 150,
        maxFilesize: 0.5,
        headers: { "My-Awesome-Header": "header value" }
      }
    };
  },
  validations: {
    materialRequest: {
      clientId: {
        required
      },
      date: {
        required
      }
    },
    newDetail: {
      rawProductId: {
        required
      },
      numberPrimaryUnits: {
        required
      },
      packages: {
        required
      },
      batch: {
        required
      },
      country: {
        required
      },
      expirationDate: {
        required
      },
      fromWarehouseId: {
        required
      },
      toWarehouseId: {
        required
      }
    }
  },
  mounted() {
    let vm = this;

    _.delay(function() {
      var current_page = "Добави";
      if (typeof vm.$route.params.id !== "undefined") {
        current_page = "Промени";
      }
      vm.$store.dispatch(SET_BREADCRUMB, [
        { title: "Приемно-предавателен протокол", route: "/material-request" },
        { title: current_page }
      ]);
    }, 300);

    if (typeof this.$route.params.id !== "undefined") {
      this.getData().then(() => {
        vm.getRawProducts();
        vm.getVendors();
        vm.getWarehouses();
      });
      // this.getFiles();
    } else {
      this.getRawProducts();
      vm.getVendors();
      vm.getWarehouses();
    }

    this.getUsers();
  },
  methods: {
    setQuantity(type, detail) {
      const rawProduct = _.find(this.raw_products, function(product) {
        return product.id === detail.rawProductId;
      });

      if (rawProduct) {
        const rawProductPrimaryUnit = rawProduct.primaryQIB;

        if (type === "numberPrimaryUnits" && detail.numberPrimaryUnits > 0) {
          detail.packages = Math.ceil(
            detail.numberPrimaryUnits / rawProductPrimaryUnit
          );
        } else if (type === "packages" && detail.packages > 0) {
          detail.numberPrimaryUnits = detail.packages * rawProductPrimaryUnit;
        }
      }
    },
    getFiles: _.debounce(function() {
      let vm = this;

      let id = this.$route.params.id;
      this.error_messages = [];
      vm.$store
        .dispatch(GET_FILES_BY_TYPE, {
          file_type: "material_request",
          resource_id: id
        })
        .then(data => {
          vm.files = data.data;
        })
        .catch(response => {
          vm.error_messages = response.data.errors;
          vm.$scrollTo("html", 420, {
            offset: -300
          });

          if (response.status === 404) {
            vm.$notification["success"]({
              message: response.status,
              description: response.message
            });
            vm.$router.push({ name: "materialRequestList" });
          }
        });
    }, 500),
    getFile: _.debounce(function(id) {
      let vm = this;
      this.error_messages = [];
      vm.$store
        .dispatch(GET_FILE, id)
        .then(data => {
          vm.streamFile(data.data.code);
        })
        .catch(response => {
          vm.error_messages = response.data.errors;
          vm.$scrollTo("html", 420, {
            offset: -300
          });

          if (response.status === 404) {
            vm.$notification["success"]({
              message: response.status,
              description: response.message
            });
            vm.$router.push({ name: "materialRequestList" });
          }
        });
    }, 500),
    streamFile: function(code) {
      window.open(APP_URL + "file/" + code);
    },
    saveDetail: function() {
      let vm = this;

      this.$v.newDetail.$touch();

      if (this.$v.newDetail.$error) {
        return;
      }

      vm.$set(
        vm.materialRequest.details,
        vm.materialRequest.details.length,
        vm.newDetail
      );

      vm.$v.$reset();
      vm.newDetail = {};
    },
    deleteDetail: function(i) {
      let vm = this;

      vm.$confirm({
        title: "Сигурен ли си, че искаш да изтриеш този запис?",
        content: "За да завършите това действие, трябва да запишете промените!",
        icon: "warning",
        okText: "Потвърди",
        cancelText: "Откажи",
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        onOk() {
          vm.materialRequest.details.splice(i, 1);
          // vm.materialRequest.details = result;
        },
        onCancel() {}
      });
    },
    save: function() {
      let vm = this;

      vm.$v.materialRequest.$touch();

      if (this.$v.materialRequest.$error) {
        vm.$scrollTo("html", 420, {
          offset: -300
        });
        return;
      }

      var action =
        typeof this.$route.params.id !== "undefined"
          ? UPDATE_MATERIALREQUEST
          : SAVE_MATERIALREQUEST;

      for (let i in vm.materialRequest.details) {
        vm.materialRequest.details[i].buy = moment(
          vm.materialRequest.details[i].buy
        ).format("YYYY-MM-DD");
        vm.materialRequest.details[i].sell = moment(
          vm.materialRequest.details[i].sell
        ).format("YYYY-MM-DD");

        // to fix
        vm.materialRequest.details[i].expirationDate = moment(
          vm.materialRequest.details[i].expirationDate
        ).format("YYYY-MM-DD");
      }

      vm.materialRequest.date = moment(vm.materialRequest.date).format(
        "YYYY-MM-DD"
      );
      this.error_messages = [];
      vm.$store
        .dispatch(action, { payload: vm.materialRequest })
        .then(data => {
          vm.$notification["success"]({
            message: data.status,
            description: data.message
          });

          vm.$nextTick(function() {
            vm.materialRequest = data.data;
            // if (typeof this.$route.params.id === "undefined") {
            //   vm.$router.push({
            //     name: "materialRequestsCreateUpdate",
            //     params: { id: data.data.id }
            //   });
            // }
            vm.$router.push({ name: "materialRequestList" });
          });
        })
        .catch(response => {
          vm.error_messages = response.data.errors;
          vm.$scrollTo("html", 420, {
            offset: -300
          });

          if (response.status === 404) {
            vm.$notification["success"]({
              message: response.status,
              description: response.message
            });
            vm.$router.push({ name: "materialRequestList" });
          }
        });
    },
    getVendors: function() {
      let vm = this;
      this.error_messages = [];
      this.$store
        .dispatch(GET_ALL_CLIENTS)
        .then(data => {
          vm.$nextTick(function() {
            vm.vendors = data.data;

            if (
              typeof this.$route.params.id !== "undefined" &&
              vm.materialRequest.clientIsArchived
            ) {
              vm.vendors.push({
                id: vm.materialRequest.clientId,
                name: vm.materialRequest.clientName + " (Архивиран)"
              });

              vm.vendors = _.uniqBy(vm.vendors, "id");
            }
          });
        })
        .catch(response => {
          vm.error_messages = response.data.errors;
          vm.$scrollTo("html", 420, {
            offset: -300
          });

          if (typeof response === "undefined" || response.status === 404) {
            vm.$notification["error"]({
              message: "Грешка 404",
              description: "Страницата не е намерена!"
            });
          }
        });
    },
    getWarehouses: function() {
      let vm = this;
      this.error_messages = [];
      this.$store
        .dispatch(GET_ALL_WAREHOUSES)
        .then(data => {
          vm.$nextTick(function() {
            vm.warehouses = data.data;

            let tempArchivedWarehouses = {};

            if (typeof this.$route.params.id !== "undefined") {
              vm.materialRequest.details.forEach(detail => {
                if (detail.fromWarehouseIsArchived) {
                  tempArchivedWarehouses[detail.fromWarehouseId] = {
                    id: detail.fromWarehouseId,
                    name: detail.fromWarehouseName + " (Архивиран)"
                  };
                }

                if (detail.toWarehouseIsArchived) {
                  tempArchivedWarehouses[detail.toWarehouseId] = {
                    id: detail.toWarehouseId,
                    name: detail.toWarehouseName + " (Архивиран)"
                  };
                }
              });

              Object.values(tempArchivedWarehouses).forEach(warehouse => {
                vm.warehouses.push(warehouse);
              });

              vm.warehouses = _.uniqBy(vm.warehouses, "id");
            }
          });
        })
        .catch(response => {
          vm.error_messages = response.data.errors;
          vm.$scrollTo("html", 420, {
            offset: -300
          });

          if (typeof response === "undefined" || response.status === 404) {
            vm.$notification["error"]({
              message: "Грешка 404",
              description: "Страницата не е намерена!"
            });
          }
        });
    },
    getRawProducts: function() {
      let vm = this;
      this.error_messages = [];
      this.$store
        .dispatch(GET_ALL_RAW_PRODUCTS)
        .then(data => {
          vm.$nextTick(function() {
            vm.raw_products = data.data.map(product => {
              const manufacturers = product.manufacturers
                .map(manufacturer => manufacturer.name)
                .join(", ");

              return {
                ...product,
                name:
                  manufacturers.length > 0
                    ? product.name + " - " + manufacturers
                    : product.name
              };
            });

            vm.materialRequest.details.forEach(detail => {
              if (detail.isArchived) {
                vm.raw_products.push({
                  id: detail.rawProductId,
                  name: detail.rawProductName + " (Архивирана)"
                });
              }
            });

            vm.raw_products = _.uniqBy(vm.raw_products, "id");
          });
        })
        .catch(response => {
          vm.error_messages = response.data.errors;
          vm.$scrollTo("html", 420, {
            offset: -300
          });

          if (typeof response === "undefined" || response.status === 404) {
            vm.$notification["error"]({
              message: "Грешка 404",
              description: "Страницата не е намерена!"
            });
          }
        });
    },
    getUsers: function() {
      let vm = this;
      this.error_messages = [];
      this.$store
        .dispatch(GET_ALL_USERS)
        .then(data => {
          vm.$nextTick(function() {
            vm.users = data.data;
          });
        })
        .catch(response => {
          vm.error_messages = response.data.errors;
          vm.$scrollTo("html", 420, {
            offset: -300
          });

          if (typeof response === "undefined" || response.status === 404) {
            vm.$notification["error"]({
              message: "Грешка 404",
              description: "Страницата не е намерена!"
            });
          }
        });
    },
    getData: function() {
      let vm = this;
      this.error_messages = [];
      let id = this.$route.params.id;
      return this.$store
        .dispatch(GET_MATERIALREQUEST, id)
        .then(data => {
          vm.$nextTick(function() {
            vm.materialRequest = data.data;
          });
        })
        .catch(response => {
          vm.error_messages = response.data.errors;
          vm.$scrollTo("html", 420, {
            offset: -300
          });

          if (typeof response === "undefined" || response.status === 404) {
            vm.$notification["error"]({
              message: "Грешка 404",
              description: "Страницата не е намерена!"
            });
            vm.$router.push({ name: "materialRequestList" });
          }
        });
    },
    handleValidation: function(isValid) {
      this.isSubmitDisabled = !isValid;
    },
    getProduct: function(prodId) {
      const product = _.find(this.raw_products, function(o) {
        return o.id == prodId;
      });

      return product ? product.name : "test";
    }
  }
};
</script>
